<template>
    <l-map
        @baselayerchange="onLayerChanged"
        style="min-height: 300px"
        :zoom="zoom"
        :center="latLng(center)"
        @click="onMapClick"
        ref="map"
    >
        <l-control-layers position="topright" :collapsed="false" :sort-layers="false" />
        <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :subdomains="tileProvider.subdomains"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"
        />

        <component v-if="mapElements" :key="index" v-for="(element, index) in mapElements" :is="element" />

        <component :is="isMarkersCluster ? 'l-marker-cluster' : 'div'">
            <l-marker
                ref="markers"
                v-for="marker in markers"
                v-bind="marker.options"
                :lat-lng="marker.latLng"
                :data-marker-id="marker.id"
                v-on="getMarkerEvent(marker)"
                :key="marker.id"
            >
                <l-icon
                    :icon-size="marker.iconSize || defaultIconSize"
                    v-if="marker.iconUrl"
                    :icon-url="marker.iconUrl"
                ></l-icon>
                <l-popup
                    v-if="marker.popup"
                    :class="markers.popupClasses"
                    :style="marker.popupStyles"
                    :options="marker.popupOptions"
                >
                    <div v-if="typeof marker.popup === 'function'" :is="marker.popup()" />
                    <component
                        v-else-if="marker.popup !== null && typeof marker.popup === 'object'"
                        :marker="marker"
                        :is="marker.popup"
                    />
                    <component v-else-if="typeof marker.popup === 'string'" v-html="marker.popup" :marker="marker" />
                </l-popup>
            </l-marker>
        </component>
    </l-map>
</template>

<script>
import { latLng } from "leaflet"
import MapTiles from "@Platon/components/map/MapTiles"
import "@Platon/components/map/VueLeafletImport"

/**
 * @typedef MapPickerMarker
 * @property {string|number} id
 * @property {LatLng} latLng
 * @property {Function|Vue.Component|string} popup
 * @property {object} markerData
 * @property {?object} options - Marker options, https://leafletjs.com/reference-1.7.1.html#marker-l-marker
 * @property {object} popupClasses
 * @property {object} popupStyles
 * @property {object} popupOptions
 * @property {Function} onMarkerClick
 */
const getTileProviders = (activeTile, enabledTiles) => {
    const filteredTiles = MapTiles.filter((item) => enabledTiles.includes(item.name))
    return filteredTiles.map((item) => {
        item.visible = activeTile === item.name
        return item
    })
}

export default {
    name: "MapMarkerPicker",

    data() {
        return {
            tileProviders: getTileProviders(this.defaultTile, this.enabledTiles)
        }
    },

    props: {
        enabledTiles: {
            default: () => ["YandexMaps", "OpenStreetMap", "Google Sat", "Google Streets", "OpenTopoMap"]
        },
        defaultTile: {
            type: String,
            default: "YandexMaps"
        },
        markers: {
            type: Array,
            default: () => []
        },
        isMarkersCluster: {
            type: Boolean,
            default: false
        },

        zoom: {
            type: Number,
            default: 12
        },

        center: {
            type: Array,
            default: () => [41.311081, 69.240562]
        },

        mapElements: {
            type: Array,
            default: () => null
        }
    },
    methods: {
        onLayerChanged(layer) {
            let CRS = L.CRS.EPSG3857
            if (layer.name === "YandexMaps") {
                CRS = L.CRS.EPSG3395
            }
            this.$refs.map.setCrs(CRS)
        },
        async getFullScreenDependency() {
            await Promise.all([
                this.addStyle("https://unpkg.com/platon-leaflet-fullscreen@0.0.1/dist/style.css"),
                this.addScript("https://unpkg.com/platon-leaflet-fullscreen@0.0.1/dist/fullscreen.js")
            ])
        },
        async getGeoCoderDependency() {
            await Promise.all([
                this.addStyle("https://unpkg.com/leaflet-geonames@0.4.9/L.Control.Geonames.css"),
                this.addScript("https://unpkg.com/leaflet-geonames@0.4.9/L.Control.Geonames.min.js")
            ])
        },
        async initSearchControl() {
            await this.getGeoCoderDependency()
            await this.wait(500)
            const map = this.getMap()
            const control = L.control.geonames({
                //position: 'topcenter', // In addition to standard 4 corner Leaflet control layout, this will position and size from top center.
                position: "topleft",
                country: "UZ",
                geonamesSearch: "https://secure.geonames.org/searchJSON", // Override this if using a proxy to get connection to geonames.
                geonamesPostalCodesSearch: "https://secure.geonames.org/postalCodeSearchJSON", // Override this if using a proxy to get connection to geonames.
                username: "shohrux_saidov", // Geonames account username.  Must be provided.
                maxresults: 15, // Maximum number of results to display per search.
                zoomLevel: null, // Max zoom level to zoom to for location. If null, will use the map's max zoom level.
                className: "leaflet-geonames-icon", // Class for icon.
                workingClass: "leaflet-geonames-icon-working", // Class for search underway.
                featureClasses: ["A", "H", "L", "P", "R", "S", "V"], // Feature classes to search against.  See: http://www.geonames.org/export/codes.html.
                baseQuery: "isNameRequired=true", // The core query sent to GeoNames, later combined with other parameters above.
                showMarker: false, // Show a marker at the location the selected location.
                showPopup: true, // Show a tooltip at the selected location.
                adminCodes: {
                    country: "UZ"
                }, // Filter results by the specified admin codes mentioned in `ADMIN_CODES`. Each code can be a string or a function returning a string. `country` can be a comma-separated list of countries.
                bbox: {}, // An object in form of {east:..., west:..., north:..., south:...}, specifying the bounding box to limit the results to.
                lang: "uz", // Locale of results.
                alwaysOpen: false, // If true, search field is always visible.
                title: "Search by location name or postcode", // Search input title value.
                placeholder: "Enter a location name" // Search input placeholder text.
            })
            map.addControl(control)
        },
        async initFullControl() {
            await this.getFullScreenDependency()
            await this.wait(500)
            this.getMap().addControl(
                new L.Control.Fullscreen({
                    title: {
                        false: this.$l("fullScreen", "Toliq qilib ochish"),
                        true: this.$l("exitFromFullScreen", "To'liq ekrandan chiqish")
                    }
                })
            )
        },
        async wait(time) {
            return new Promise((resolve) => {
                setTimeout(resolve, time)
            })
        },
        initMapControls() {
            this.initFullControl()
            this.initSearchControl()
        },
        latLng,
        getMap() {
            return this.$refs.map.mapObject
        },
        onMapClick(event) {
            this.$emit("click", this, event)
        },

        /**
         * @param {string|number} id
         */
        openPopup(id) {
            for (let marker of this.$refs.markers) {
                if (marker.$attrs["data-marker-id"] === id) {
                    marker.mapObject.openPopup()
                    return
                }
            }
        },

        /**
         * @param {string|number} id
         */
        closePopup(id) {
            for (let marker of this.$refs.markers) {
                if (marker.$attrs["data-marker-id"] === id) {
                    marker.mapObject.closePopup()
                    return
                }
            }
        },

        /**
         * @param {MapPickerMarker} marker
         */
        getMarkerEvent(marker) {
            let options = {}

            if (typeof marker.onMarkerClick === "function") {
                options["click"] = () => marker.onMarkerClick(marker)
            }

            return options
        }
    },
    computed: {
        defaultIconSize() {
            return [30, 30]
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/styles/leafletmap";
</style>
